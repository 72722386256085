import { NextPageContext } from 'next';
import { getConfig } from '@belong/configs/next/config';
import { isBrowser } from '@belong/env';

export type PageProps = {
  statusCode: number;
  host: string;
  cookie: string;
  isBlue: boolean;
};

/**
 *
 * @param context
 * @returns { cookie, host, statusCode }
 * Used
 */
export const getAppProps = (context: NextPageContext): PageProps => {
  let cookie;
  let host;

  if (isBrowser()) {
    host = window.location.host;
    cookie = window.document.cookie;
  } else {
    const { publicRuntimeConfig } = getConfig();

    host = publicRuntimeConfig.env.host || '';
    cookie = context.req?.headers?.cookie || '';
  }

  return { cookie, host, isBlue: host.includes('blue'), statusCode: 200 };
};
