import { ReactElement, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { AppProps } from 'next/app';
import { getConfig } from '@belong/configs/next/config';
import Head from 'next/head';
import { Cookies } from 'react-cookie';

import AnalyticsContext from '@belong/providers/analyticsEvents/Context';
import { getNativeAppCookie } from '@belong/cookies';
import { logAppInfo, initPlatformConfig, getCurrentEnvironment } from '@belong/env';
import { useBlueGreen } from '@belong/react-hooks';
import { ENVS } from '@belong/types';
import { GlobalStyles, THEME } from '@belong/themes';
import { GlobalErrorHandler } from '@belong/logging';
import CanonicalLink from '@belong/ui-components/components/global/CanonicalLink';
import { FEATURES, FeatureTogglesContext, FeaturesManager } from '@belong/feature-toggles';
import { ChatIframer } from '@belong/chat-utils';
import { PolyfillScript } from '@belong/ui-components/components/global/Scripts';

import DefaultLayout from 'src/components/layout/DefaultLayout';
import Providers from 'src/components/providers';
import AnalyticsDispatcher from 'src/components/providers/AnalyticsDispatcher';
import { debugNavigation } from 'src/core/routes';
import { DEFAULT_META } from 'src/core/constants';
import { routeConfig } from 'src/core/bootstrap';
import type { ISupportServerAppProps } from 'src/core/types';
import ErrorPage from './_error';

const { publicRuntimeConfig } = getConfig();
const { workspace } = publicRuntimeConfig;

const Toolbox = dynamic(() => import('@belong/toolbox/src/view'));

export default function SupportApp({ Component, router, pageProps }: AppProps<ISupportServerAppProps>) {
  debugNavigation(pageProps, router);
  const {
    meta,
    host,
    cookie,
    config,
    breadcrumbs,
    pageUrl,
    correlationId,
    isLoggedIn,
    features,
    headerMenuContent,
    footerMenuContent,
    searchServerUrl,
    ...componentProps
  } = pageProps;
  const {
    pageTitle,
    description,
    keywords,
    journeyName,
    flowName,
    pageName,
    noIndex,
    isAuthenticatedPage,
    ...otherProps
  } = meta || {};
  const allCookies = new Cookies(cookie);
  // Init FeaturesManager every time, this can pick up the cookie toggles-override changes
  FeaturesManager.init(correlationId, features);
  useBlueGreen(host);
  useEffect(() => {
    logAppInfo('Support', correlationId);
  }, [correlationId]);
  initPlatformConfig(getNativeAppCookie(cookie));

  const PageComponent = pageProps.statusCode >= 400 ? ErrorPage : Component;
  const renderPageWithLayout = (): ReactElement => {
    if (!headerMenuContent || !footerMenuContent) {
      return <ErrorPage statusCode={500} />;
    }
    if (router.pathname === '/chat') {
      return <PageComponent {...componentProps} />;
    }
    return (
      <DefaultLayout
        headerMenuContent={headerMenuContent}
        footerMenuContent={footerMenuContent}
        breadcrumbs={breadcrumbs}
        pageAlert={componentProps.data?.alert}
        isLoggedIn={isLoggedIn}
      >
        <PageComponent {...componentProps} />
        {features[FEATURES.LIVE_CHAT_IFRAME] && <ChatIframer />}
        {/* Note: AnalyticsDispatcher needs to come after the PageComponent to allow for any nested AnalyticsContexts to take effect */}
        <AnalyticsDispatcher pageData={pageProps.data} isAuthenticatedPage={isAuthenticatedPage} />
      </DefaultLayout>
    );
  };

  return (
    <FeatureTogglesContext.Provider value={features}>
      <Providers theme={THEME} cookies={allCookies} searchServerUrl={searchServerUrl}>
        <GlobalErrorHandler appRoutes={routeConfig.routes} />
        <CanonicalLink pageUrl={pageUrl} canonicalUrl={meta?.canonicalUrl} />
        <AnalyticsContext
          value={{
            workspace,
            journey: journeyName || 'Extend',
            pageTitle,
            pageName,
            flow: flowName,
            // pageAuthenticated means the page is only accessible to logged in users
            pageAuthenticated: isAuthenticatedPage ? 'yes' : 'no',
            ...otherProps
          }}
        />
        <Head>
          <title>{`${pageTitle || DEFAULT_META.pageTitle}`}</title>
          <meta name="description" content={description || DEFAULT_META.description} />
          <meta name="format-detection" content="telephone=no" />
          <meta name="keywords" content={keywords || DEFAULT_META.keywords} />
          {noIndex && <meta name="robots" content="noindex" />}
        </Head>
        <GlobalStyles hasBodyScrollLock />
        <PolyfillScript scriptName={'broadcastchannel.min.js'} />
        {renderPageWithLayout()}
        {getCurrentEnvironment() !== ENVS.PRODUCTION && (
          <Toolbox pageProps={{ pageData: pageProps.data, features }} runtimeConfig={publicRuntimeConfig} />
        )}
      </Providers>
    </FeatureTogglesContext.Provider>
  );
}
